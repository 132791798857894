<template>
  <div class="fw-person">
    <label
      v-if="selectable"
      class="relative select-none"
      :class="[
        {
          'fw-row-checkbox-selectable': selectable,
          'fw-row-checkbox': !selectable,
        },
        customClass,
      ]"
    >
      <input
        v-if="selectable"
        v-model="currentValue"
        type="checkbox"
        class="opacity-0 absolute"
        @change="selectPerson"
      />
      <div
        class="fw-row-checkbox-inside flex w-full gap-1 items-center relative justify-between group"
        :class="{
          'pr-4': !selectable && view === 'row',
          'cursor-pointer hover:bg-gray-200 hover:bg-opacity-50': clickable,
          'rounded-lg': !noStyle,
          'px-3 py-1.5': paddingless === false,
        }"
      >
        <div v-if="selectable" class="check h-4 w-4 rounded p-1"></div>
        <div v-if="view === 'row'" class="ml-5">
          <slot name="leading"></slot>
        </div>
        <fw-avatar class="mr-1.5 flex-shrink-0" size="sm" :user="person" />
        <div class="text-left leading-5">
          <div class="flex gap-2">
            <v-clamp autoresize :max-lines="1" class="font-medium">
              {{ getPersonName() }}
            </v-clamp>
            <v-clamp
              v-if="showShortAndFullname"
              autoresize
              :max-lines="1"
              class="text-gray-500 text-xs hidden md:block"
            >
              {{ person.display_name }}
            </v-clamp>
            <div v-if="person.number" class="text-xs text-gray-500">
              {{ person.number }}
            </div>
            <div v-if="isActive" class="text-xs font-bold text-primary uppercase mx-1">
              {{ $t('active') }}
            </div>
          </div>
          <div v-if="!hasSecondLineSlot" class="text-xs text-gray-500 font-normal flex gap-2 items-center">
            <v-clamp autoresize :max-lines="1">
              {{ person.email }}
            </v-clamp>
          </div>
          <slot name="secondline"></slot>
        </div>
        <div class="flex-1"></div>
        <div v-if="hasOptionsSlot" class="flex justify-end absolute top-0 right-5 bottom-0 items-center">
          <slot name="options"></slot>
        </div>
      </div>
    </label>
    <div
      v-else
      class="relative w-full flex items-center"
      :class="[
        { 'rounded-lg hover:bg-gray-200 hover:bg-opacity-50': !noStyle && (selectable || clickable) },
        customClass,
      ]"
    >
      <button
        type="button"
        class="fw-row-checkbox-inside flex flex-1 gap-1 relative justify-between group"
        :class="{
          'border border-gray-100 md:border-none pr-4': !selectable && view === 'row' && !noStyle,
          'p-2': paddingless === false,
          'opacity-50': rejected,
          'cursor-default': !clickable && !selectable,
        }"
        @click="processClick()"
      >
        <div v-if="rejected" class="absolute border-b border-gray-700 top-1/2 h-2 -mt-1.5 left-2 right-2"></div>
        <slot name="leading"></slot>
        <fw-avatar class="mr-1.5 flex-shrink-0" size="sm" :user="person" />
        <div class="text-left leading-5">
          <div class="flex gap-2">
            <v-clamp autoresize :max-lines="1" class="font-semibold">
              {{ getPersonName() }}
            </v-clamp>
            <v-clamp
              v-if="showShortAndFullname"
              autoresize
              :max-lines="1"
              class="text-gray-500 text-xs hidden md:block"
            >
              {{ person.display_name }}
            </v-clamp>
            <div v-if="person.number" class="text-xs text-gray-500">
              {{ person.number }}
            </div>
            <div v-if="isActive" class="text-xs font-bold text-primary uppercase mx-1">
              {{ $t('active') }}
            </div>
            <div v-if="itsYou">
              <fw-icon-start-smile-solid class="w-4 h-4 text-primary" />
            </div>
          </div>
          <div v-if="!hasSecondLineSlot" class="text-sm text-gray-500 font-normal flex gap-2 items-center break-all">
            <v-clamp autoresize :max-lines="1">
              {{ person.email }}
            </v-clamp>
          </div>
          <slot name="secondline"></slot>
        </div>
        <div class="flex-1"></div>
      </button>
      <div v-if="hasOptionsSlot" class="flex items-center mx-2">
        <slot name="options"></slot>
      </div>
    </div>
    <slot name="modals"></slot>
  </div>
</template>

<script>
export default {
  name: 'PersonBase',

  props: {
    noStyle: {
      type: Boolean,
      default: false,
    },
    view: {
      type: String,
      default: 'row',
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    fullname: {
      type: Boolean,
      default: false,
    },
    shortname: {
      type: Boolean,
      default: false,
    },
    person: {
      type: Object,
      default() {
        return {
          full_name: 'Name',
          number: '12345',
        }
      },
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    rejected: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      currentValue: false,
    }
  },

  computed: {
    hasSecondLineSlot() {
      return !!this.$slots['secondline']
    },
    hasOptionsSlot() {
      return !!this.$slots['options']
    },
    showShortAndFullname() {
      return (
        this.shortname &&
        this.fullname &&
        this.person.display_name &&
        this.person.display_name !== this.person.full_name
      )
    },
    itsYou() {
      return this.loggedUser && this.person && this.loggedUser.key === this.person.key
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
  },

  created() {
    this.currentValue = this.checked
  },

  methods: {
    selectPerson() {
      console.log('select person')
      if (this.selectable) {
        this.$emit('selected', this.currentValue)
      }
    },
    getPersonName() {
      return !this.fullname ? this.person.display_name || this.person.full_name : this.person.full_name
    },
    processClick() {
      console.log('process click')
      if (!this.selectable && this.clickable) {
        this.$emit('clicked', true)
      }
    },
  },
}
</script>

<style>
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside {
  background-color: rgba(45, 212, 191, 0.1);
}

.fw-row-checkbox-selectable:hover input[type='checkbox']:checked + .fw-row-checkbox-inside {
  background-color: rgba(45, 212, 191, 0.5);
}
.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside .check {
  position: absolute;
  background: white;
  border: solid 1px #6accb3;
}
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside .check {
  background: #30a898
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center center;
  border-color: #30a898;
  background-size: 80%;
}

.fw-row-checkbox-selectable input[type='checkbox']:active + .fw-row-checkbox-inside .check {
  border-color: #30a898;
}

.fw-row-checkbox-selectable input[type='checkbox']:focus + .fw-row-checkbox-inside .check {
  border-color: #30a898;
}

.fw-row-checkbox-selectable input[type='checkbox']:active + .fw-row-checkbox-inside {
  outline-style: solid;
  outline-width: 0px;
  outline-color: rgba(45, 212, 191, 0.5);
}

.fw-row-checkbox-selectable input[type='checkbox']:focus + .fw-row-checkbox-inside {
  outline-style: solid;
  outline-width: 0px;
  outline-color: rgba(45, 212, 191, 0.5);
}

.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside .repo-icon {
  background-color: rgba(229, 231, 235, 0.5);
}
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside .repo-icon {
  background-color: white;
}
.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside .id-label {
  background-color: rgb(234, 234, 234);
  color: rgb(59, 58, 58);
}
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside .id-label {
  background-color: #30a898;
  color: white;
}

.fw-row-checkbox .fw-row-checkbox-inside .repo-icon {
  background-color: rgba(230, 232, 234, 0.5);
}
.fw-row-checkbox:hover .fw-row-checkbox-inside .repo-icon {
  background-color: #def8ef;
}

.fw-row-checkbox .fw-row-checkbox-inside .id-label {
  background-color: rgb(234, 234, 234);
  color: rgb(59, 58, 58);
}
.fw-row-checkbox .fw-row-checkbox-inside .id-label {
  background-color: #30a898;
  color: white;
}
</style>

<i18n>
{
  "pt": {
    "active": "Ativo"
  },
  "en": {
    "active": "Active"
  }
}
</i18n>
