<template>
  <div class="bg-gray-50">
    <div
      class="flex flex-col w-full items-center bg-gray-200 bg-opacity-50 mb-2 outline-none focus:ring-2 ring-primary ring-opacity-30 rounded-xl"
    >
      <div class="flex w-full items-center px-3 py-1.5">
        <div class="w-6 ml-1">
          <fw-icon-loading v-if="loading" class="w-6 h-6" />
          <fw-icon-search v-else class="w-6 h-6 opacity-50" />
        </div>
        <slot name="prefix"></slot>
        <input
          v-model="searchTerm"
          class="outline-none font-semibold flex-1 px-1 py-0.5 bg-transparent ml-1"
          type="text"
          :placeholder="placeholder"
          @keyup.enter="search()"
        />

        <button class="p-2 rounded-xl" @click="deleteSearchTerm()">
          <fw-icon-delete-input
            class="w-5 h-5"
            :class="{ 'text-gray-500': searchTerm.length > 0, 'text-gray-300': searchTerm.length === 0 }"
          ></fw-icon-delete-input>
        </button>

        <b-dropdown
          v-if="
            (filterOptions.length > 0 || showTimePeriod || (showUserPicker && availableUsersKeys.length > 0)) &&
              showFilters
          "
          ref="filterOptionsDropdown"
          aria-role="list"
          position="is-bottom-left"
          :can-close="canClose"
          class="z-10"
          :scrollable="scrollable"
          :max-height="maxDropdownHeight"
        >
          <template #trigger="{ active }">
            <fw-button
              size="sm"
              class="flex gap-2"
              :class="{
                'opacity-100': active,
                'font-semibold': filtersCounter > 0,
                'font-normal': filtersCounter == 0,
              }"
            >
              <div class="max-width-text flex items-center gap-2">
                <fw-icon-filter class="fill-current text-gray-500 h-5 w-5"></fw-icon-filter>
                {{ $t('filter') }}
              </div>
              <div
                v-if="filtersCounter > 0"
                class="rounded-full px-1 py-0.5 text-xs font-semibold bg-gray-200"
                style="min-width: 17px;"
              >
                {{ filtersCounter }}
              </div>
            </fw-button>
          </template>

          <div style="min-width:300px;" class="mb-3 z-10">
            <div v-for="(filter, key) in filterOptions" :key="'filter_cat_' + key">
              <fw-label v-if="filter.label && filter.label.length > 0" class="ml-4 pt-3 pb-2">{{
                filter.label
              }}</fw-label>
              <div class="flex flex-wrap gap-2 mx-4">
                <div
                  v-for="(option, o) in filter.options"
                  :key="'filter_cat_' + key + '_opt_' + o"
                  class="filter-tag"
                  :class="{ active: checkIsFilterActive(filter.key, option.key) }"
                  @click="selectFilter(filter.key + ':' + option.key)"
                >
                  {{ option.label }}
                </div>
              </div>
              <div class="clear"></div>
            </div>

            <div v-if="showTimePeriod" class="my-5">
              <fw-label class="ml-4">{{ timePeriodLabel }}</fw-label>
              <div class="flex">
                <div class="flex w-full mx-4 gap-2">
                  <div
                    ref="filterDatepickerTrigger"
                    class="py-1.5 flex-1 text-center justify-around rounded-md border  flex text-sm cursor-pointer px-2"
                    :class="{
                      'text-gray-500 border-gray-200 font-semibold': dates.length == 0,
                      'text-primary border-primary font-bold': dates.length > 0,
                    }"
                    @click="toogleDateRangePicker"
                  >
                    <div v-if="dates.length > 0">{{ dates[0] | formatDate }}</div>
                    <faicon v-else icon="infinity" class="text-gray-400 h-5 w-5 self-center justify-center"></faicon>
                    <div class="w-2">-</div>
                    <div v-if="dates.length > 0">{{ dates[1] | formatDate }}</div>
                    <div v-else>{{ $t('today') }}</div>
                  </div>
                  <div
                    v-if="dates.length > 0"
                    class="self-center h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-800"
                    @click="deleteDateRange"
                  >
                    <fw-icon-close-circle class="fill-current h-5 w-5"></fw-icon-close-circle>
                  </div>
                </div>
              </div>
              <div v-show="showDateRangePicker" class="filter-datepicker">
                <b-datepicker
                  ref="filterDatepicker"
                  v-model="dates"
                  inline
                  range
                  trap-focus
                  locale="pt-PT"
                ></b-datepicker>
              </div>
            </div>
            <div v-if="showUserPicker && availableUsersKeys.length > 0" class="my-5">
              <fw-label class="ml-4">{{ userPickerLabel }}</fw-label>

              <div class="flex flex-col gap-4">
                <div v-for="userKey in filterUsers" :key="userKey" class="flex w-full px-4 gap-2">
                  <Person
                    class="flex-1"
                    :no-style="true"
                    :person="users[userKey]"
                    :paddingless="true"
                    :selectable="false"
                    :clickable="false"
                  >
                  </Person>
                  <div
                    class="self-center h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-800"
                    @click="deleteUser(userKey)"
                  >
                    <fw-icon-close-circle class="fill-current h-5 w-5"></fw-icon-close-circle>
                  </div>
                </div>

                <div class="flex w-full px-4 gap-2" @click="openUserPickerModal">
                  <div
                    class="py-1.5 select-none flex-1 text-center justify-around rounded-md border text-sm cursor-pointer px-2"
                    :class="{
                      'text-gray-500 border-gray-200 font-semibold': true,
                      'text-primary border-primary font-bold': false,
                    }"
                  >
                    <div class="font-normal text-xs h-8 py-2 text-gray-400">
                      {{ $t('clickToChooseUser') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end px-4 pt-0.5 pb-5">
            <fw-button type="primary" class="px-4 py-2 rounded-md" @click.native="search(true)">
              {{ $t('applyFilters') }}
            </fw-button>
          </div>
        </b-dropdown>

        <b-dropdown v-if="orderByOptions && orderByOptions.length > 0" aria-role="list" :position="'is-bottom-left'">
          <template #trigger="{ active }">
            <fw-button
              size="sm"
              class="flex"
              :class="{
                'opacity-100': active,
                'font-semibold': orderItemsBy,
                'font-normal': !orderItemsBy,
              }"
            >
              <OrderToggle :order="orderDirection" :inactive-color="'text-gray-500'" :size="'sm'"></OrderToggle>
              <div class="hidden md:block text-gray-800 ml-1 max-width-text">
                {{ orderItemsBy ? orderItemsBy.label : $t('order') }}
              </div>
            </fw-button>
          </template>

          <fw-label class="ml-4">{{ $t('orderBy') }}</fw-label>
          <b-dropdown-item
            v-for="(orderitem, key) in orderByOptions"
            :key="key"
            aria-role="listitem"
            custom
            class="px-4 select-none py-2 flex justify-start gap-3 items-center cursor-pointer z-10"
            :class="{ 'font-semibold': orderItemsBy && orderItemsBy.key === orderitem.key }"
            @click.native="toogleOrderItems(orderitem)"
          >
            <div class="w-5">
              <OrderToggle
                v-if="orderItemsBy && orderItemsBy.key === orderitem.key"
                class="bg-white"
                :order="orderDirection"
                :inactive-color="'text-gray-500'"
                :size="'sm'"
              ></OrderToggle>
              <fw-icon-order-by v-else-if="orderitem.type === 'date'" class="h-5 w-5 text-gray-900"></fw-icon-order-by>
              <div v-else-if="orderitem.type === 'string'" class="text-gray-900 h-5 w-5 text-center text-base -mt-1">
                Az
              </div>
              <div v-else-if="orderitem.type === 'number'" class="text-gray-900 h-5 w-5 text-center text-base -mt-1">
                12
              </div>
            </div>
            <div class="flex-1 text-left">
              {{ orderitem.label }}
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <fw-button type="transparent-primary" class="ml-3" @click.native="search()">{{ $t('searchText') }}</fw-button>
      </div>
      <div class="px-1 w-full">
        <div class="tags-box" :class="{ active: filtersCounter > 0 && showFilters }">
          <div class="flex-1 flex gap-2 overflow-x-auto overflow-y-hidden pb-1 px-2">
            <slot name="tags"></slot>
          </div>
        </div>
      </div>
    </div>
    <fw-modal :active.sync="userPickerModal" boxed="sm" size="min" width="42rem" @close="closeModal">
      <ModalPersonSearchSelector
        :users="users"
        :available-users-keys="availableUsersKeys"
        @close="closeModal"
        @select="selectUserSearch"
      ></ModalPersonSearchSelector>
    </fw-modal>
  </div>
</template>

<script>
import OrderToggle from '@/fw-modules/fw-core-vue/ui/components/buttons/OrderToggle'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'
import ModalPersonSearchSelector from '@/fw-modules/fw-core-vue/ui/components/modals/ModalPersonSearchSelector'

export default {
  name: 'ContextualSearch',
  components: {
    OrderToggle,
    Person,
    ModalPersonSearchSelector,
  },

  props: {
    showFilters: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canClose: {
      // When a modal is about to close this should be false, so the dropdown doesn't close after
      type: Boolean,
      default: true,
    },
    orderByOptions: {
      type: Array,
      default: () => [],
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    multifilter: {
      type: Boolean,
      default: false,
    },
    appliedUsers: {
      type: Array,
      default: () => [],
      validator: p => {
        return typeof Array.isArray(p) || p === null
      },
    },
    appliedFilters: {
      type: Array,
      default: () => [],
    },
    appliedSort: {
      type: String,
    },
    appliedSortDirection: {
      type: String,
    },
    startValue: {
      type: String,
    },
    startPeriod: {
      type: String,
      default: null,
      validator: p => {
        return typeof p == 'string' || p === null
      },
    },
    endPeriod: {
      type: String,
      default: null,
      validator: p => {
        return typeof p == 'string' || p === null
      },
    },
    showTimePeriod: {
      type: Boolean,
      default: false,
    },
    timePeriodLabel: {
      type: String,
      default: function() {
        return this.$t('timePeriod')
      },
    },
    showUserPicker: {
      type: Boolean,
      default: false,
    },
    userPickerLabel: {
      type: String,
      default: function() {
        return this.$t('userLabel')
      },
    },
    placeholder: {
      type: String,
      default: function() {
        return this.$t('searchPlaceholder')
      },
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    users: {
      type: Object,
      default: () => {},
    },
    availableUsersKeys: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dates: [],
      filterUsers: [],
      started: false,
      searchTerm: '',
      selectedFilters: [],
      debounceTimer: null,
      orderDirection: 'none',
      orderItemsBy: null,
      showDateRangePicker: false,
      userPickerModal: false,
    }
  },

  computed: {
    filtersCounter() {
      return (
        this.appliedFilters.length +
        (this.startPeriod != null ? 1 : 0) +
        (this.user != null ? 1 : 0) +
        this.appliedUsers.length
      )
    },

    maxDropdownHeight() {
      return window.innerHeight * 0.65
    },

    filterDatepickerPosition() {
      if (!this.showDateRangePicker) return ''
      const trigger = this.$refs.filterDatepickerTrigger
      const rect = trigger.getBoundingClientRect()
      let top = rect.top + window.scrollY
      let left = rect.left + window.scrollX
      top += trigger.clientHeight

      return `position: 'absolute'; top: ${top}px; left: ${left}px; z-index: '99'`
    },
  },

  watch: {
    startPeriod(newvalue) {
      if (newvalue == null) {
        this.dates = []
      }
    },

    dates(newValue) {
      if (newValue && newValue.length == 2 && this.showDateRangePicker) {
        this.showDateRangePicker = false
      }
      this.$emit('dates-changed', newValue)
    },

    appliedUsers(newValue) {
      this.filterUsers = newValue
    },

    appliedSort(newValue) {
      if (newValue.length > 0) {
        this.orderItemsBy = this.orderByOptions.find(item => item.key === newValue)
      } else {
        this.orderItemsBy = null
      }
    },

    startValue(newValue) {
      if (!this.started) {
        this.started = true
        this.searchTerm = newValue
      }
    },

    appliedSortDirection(newValue) {
      this.orderDirection = newValue.toUpperCase() == 'ASC' || newValue.toUpperCase() == 'DESC' ? newValue : 'none'
    },

    appliedFilters(newValue) {
      this.selectedFilters = JSON.parse(JSON.stringify(newValue))
    },

    searchTerm(newValue) {
      console.log('search term changed', newValue)
      this.debounce(() => {
        console.log('debounced')
        this.$emit('input-changed', newValue)
      }, 500)
    },
  },

  mounted() {
    if (!this.started) {
      this.$nextTick(() => {
        this.selectedFilters = JSON.parse(JSON.stringify(this.appliedFilters))
        this.orderDirection =
          this.appliedSortDirection &&
          (this.appliedSortDirection.toUpperCase() == 'ASC' || this.appliedSortDirection.toUpperCase() == 'DESC')
            ? this.appliedSortDirection
            : 'none'
        this.orderItemsBy = this.orderByOptions.find(item => item.key === this.appliedSort)
        this.searchTerm = this.startValue
        this.filterUsers = this.appliedUsers
      })
    }
  },

  methods: {
    selectUserSearch(users) {
      this.filterUsers = users
      this.userPickerModal = false
      //this.$emit('user-changed', user)
      //wait 100ms for the modal to close
      setTimeout(() => {
        this.$refs.filterOptionsDropdown.toggle()
      }, 50)
    },
    closeModal() {
      this.userPickerModal = false
      setTimeout(() => {
        this.$refs.filterOptionsDropdown.toggle()
      }, 50)
    },
    openUserPickerModal() {
      this.userPickerModal = true
    },

    search(toggleFilterDropdown = false) {
      if (toggleFilterDropdown) {
        this.$refs.filterOptionsDropdown.toggle()
      }

      this.showDateRangePicker = false
      //this.filterUsers = this.appliedUsers
      this.$emit('search', {
        term: this.searchTerm,
        filters: this.selectedFilters,
        orderBy: this.orderItemsBy != null ? this.orderItemsBy.key : null,
        orderDirection: this.orderItemsBy != null ? this.orderDirection : null,
        filterUsers: this.filterUsers,
        dates: this.dates,
      })
    },

    deleteUser(userKey) {
      this.filterUsers = this.filterUsers.filter(user => user != userKey)
      this.$emit('delete-user')
      setTimeout(() => {
        this.$refs.filterOptionsDropdown.toggle()
      }, 50)
    },

    deleteDateRange() {
      this.dates = []
      this.showDateRangePicker = false
    },

    toogleDateRangePicker() {
      this.showDateRangePicker = !this.showDateRangePicker
    },

    clearDate(id) {
      if (id == 'start') {
        this.dateStartPeriod = null
      } else {
        this.dateEndPeriod = null
      }
    },

    toogleOrderItems(option) {
      if (this.orderItemsBy != null && this.orderItemsBy.key == option.key) {
        if (this.orderDirection.toUpperCase() === 'ASC') {
          this.orderDirection = 'DESC'
        } else {
          this.orderItemsBy = null
          this.orderDirection = 'none'
        }
      } else {
        this.orderItemsBy = option
        this.orderDirection = 'ASC'
      }
      this.$emit('sort-order-changed', { key: this.orderItemsBy, direction: this.orderDirection })
    },

    debounce(func, timeout = 300) {
      if (this.debounceTimer !== null) {
        clearTimeout(this.debounceTimer)
      }
      this.debounceTimer = setTimeout(() => {
        func.apply()
      }, timeout)
    },

    selectFilter(filter) {
      var parts = filter.split(':')
      if (parts.length > 1 && (parts[1] == 'reset' || !this.multifilter)) {
        //reset
        this.selectedFilters = this.selectedFilters.filter(function(e) {
          return !e.startsWith(parts[0] + ':')
        })
        if (!this.multifilter) {
          this.selectedFilters.push(filter)
        }
      } else {
        if (this.selectedFilters.includes(filter)) {
          this.selectedFilters = this.selectedFilters.filter(function(e) {
            return e !== filter
          })
        } else {
          this.selectedFilters.push(filter)
        }
      }

      this.$emit('filter-changed', this.selectedFilters)
    },

    checkIsFilterActive(filterGroupKey, filterValueKey) {
      if (filterValueKey === 'reset') {
        for (var i = 0; i < this.selectedFilters.length; i++) {
          if (this.selectedFilters[i].startsWith(filterGroupKey + ':')) {
            return false
          }
        }
        return true
      }

      // Check any valid option
      if (this.selectedFilters.includes(filterGroupKey + ':' + filterValueKey)) {
        return true
      }

      return false
    },

    deleteSearchTerm() {
      this.searchTerm = ''
    },
  },
}
</script>

<style scoped lang="postcss">
.tags-box {
  opacity: 0;
  height: 0px;
  transition: all 300ms ease-in-out;
  @apply flex items-center w-full py-0;
}
.tags-box.active {
  opacity: 1;
  height: 40px;
}
.filter-tag {
  @apply px-2.5 select-none py-1 flex items-center cursor-pointer bg-gray-100 text-gray-600 rounded-lg text-sm;
}
.filter-tag.active {
  @apply bg-primary text-white font-medium;
}

.max-width-text {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search_loader {
  @apply absolute top-1/2 left-1/2 -ml-3 -mt-3 h-6 w-6;
  animation: searching 600ms infinite linear;
}

@keyframes searching {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.search_loader_out {
  @apply h-6 w-6 relative;
}
</style>

<style lang="postcss">
.filter-datepicker .datepicker .dropdown-content {
  box-shadow: none;
  @apply border-gray-200;
}
</style>

<i18n>
{
  "en": {
    "searchPlaceholder": "Search...",
    "clickToChooseUser": "Click to select user",
    "applyFilters": "Apply filters",
    "searchText": "Search",
    "today": "Today",
    "filter": "Filter",
    "order": "Order",
    "orderBy": "Order by",
    "timePeriod": "Time period",
    "userLabel": "User"
  },
  "pt": {
    "searchPlaceholder": "Pesquisar...",
    "clickToChooseUser": "Clique para escolher utilizador",
    "applyFilters": "Aplicar filtros",
    "searchText": "Pesquisar",
    "today": "Hoje",
    "filter": "Filtrar",
    "order": "Ordenar",
    "orderBy": "Ordenar por",
    "timePeriod": "Período temporal",
    "userLabel": "Utilizador"
  }
}
</i18n>
